<template>    
    <div class="graphBody">
        <!-- <h1> graph test4444</h1> -->
        <el-button type="success" @click="ClickHandler"> Refresh map </el-button> 
        <div id="cy" class="graphCy" > 
        </div>
    </div>    
</template>


<script>
import * as cytoscape from 'cytoscape';
import d3Force from 'cytoscape-d3-force';
// import cxtmenu from 'cytoscape-cxtmenu';
// 所以按右鍵的圖形多選.. 總共要幾種..?
// 移除節點、展開/收合節點、持股摘要、基本資料

export default {
    data() {
        return {
             dataNodes : {},
             derrickData : {
                  edges:[
                    {id:"e0",source:"70556945",target:"林群國",width:1},
                    {id:"e1",source:"70556945",target:"楊雅玲",width:1},
                    {id:"e2",source:"70556945",target:"楊家明",width:1},
                    {id:"e3",source:"70556945",target:"楊秀怜",width:1},
                    {id:"e4",source:"70556945",target:"賴佳誼",width:1},
                    {id:"e5",source:"70556945",target:"林孟津",width:1},
                    {id:"e6",source:"70556945",target:"顏良修",width:1},
                    {id:"e7",source:"林群國",target:"12665305",width:1},
                    {id:"e8",source:"林群國",target:"70584647",width:1},
                ],
                nodes:[
                    {name:"捷智商訊科技股份有限公司",id:"70556945",type:"company",bg:"#FFFFCE",Supervisors:[]},
                    {name:"財宏科技股份有限公司",id:"12665305",type:"company",bg:"#FFFFCE",Supervisors:[]},
                    {name:"普鴻資訊股份有限公司",id:"70584647",type:"company",bg:"#FFFFCE",Supervisors:[]},
                    {name:"林群國",id:"林群國",type:"people",bg:"#66CC00",Supervisors:[{JobTitle:"董事長",LegPersonRep:"普鴻資訊股份有限公司",HoldStkNm:"7549166",Percentage:0.32}]},
                    {name:"楊雅玲",id:"楊雅玲",type:"people",bg:"#FFFFCE",Supervisors:[{JobTitle:"董事",LegPersonRep:"普鴻資訊股份有限公司",HoldStkNm:"7549166",Percentage:0.32}]},
                    {name:"楊家明",id:"楊家明",type:"people",bg:"#FFFFCE",Supervisors:[{JobTitle:"董事",LegPersonRep:"普鴻資訊股份有限公司",HoldStkNm:"7549166",Percentage:0.32}]},
                    {name:"楊秀怜",id:"楊秀怜",type:"people",bg:"#FFFFCE",Supervisors:[{JobTitle:"董事",LegPersonRep:"",HoldStkNm:"426276",Percentage:0.018}]},
                    {name:"賴佳誼",id:"賴佳誼",type:"people",bg:"#FFFFCE",Supervisors:[{JobTitle:"獨立董事",LegPersonRep:"",HoldStkNm:"0",Percentage:0}]},
                    {name:"林孟津",id:"林孟津",type:"people",bg:"#FFFFCE",Supervisors:[{JobTitle:"獨立董事",LegPersonRep:"",HoldStkNm:"0",Percentage:0}]},
                    {name:"顏良修",id:"顏良修",type:"people",bg:"#FFFFCE",Supervisors:[{JobTitle:"獨立董事",LegPersonRep:"",HoldStkNm:"410",Percentage:0.0000177}]},
                ]
            },
            
        };
    },
    computed: {
      getBG() {
        return (id) => {
          let setBG = "";
          switch (id) {
            case 0:
              setBG = "#BBFFFF";
              break;
            case 1:
              setBG = "#BBFFBB";
              break;
            case 2:
              setBG = "#FFFFCE";
              break;
            case 3:
              setBG = "#FFD9EC";
              break;
          }
          return setBG;
        };
      },
    },
    methods: {
      async ClickHandler() {
        this.refreshCy();
      },
        async draw() {
            await this.getMap();


            cytoscape.use( d3Force );
            const vm = this;


            var cy = cytoscape({
                container: document.getElementById('cy'), // container to render in
                style: [ // the stylesheet for the graph
                {
                    selector: 'node',
                    style: {
                    'content': 'data(name)',
                    'text-valign': 'center',
                    'text-halign': 'right',
                    'height': '20px',
                    'width': '20px',
                    'background-color': 'data(bg)', // '#666',
                    'border-width': '3px',
                    'border-color': '#333',              
                    'color': '#333',
                    'label': 'data(name)',

                    }
                },                
                {
                    selector: 'edge',
                    style: {
                    'width': 'data(width)',
                    'line-color': '#ccc',
                    'target-arrow-color': '#888',
                    'curve-style': 'bezier',
                    }
                },
                {
                    selector: ':selected',
                    css: {
                    'background-color': 'SteelBlue',
                    'line-color': 'black',
                    'target-arrow-color': 'black',
                    'source-arrow-color': 'black'
                    }
                }
                ],
                wheelSensitivity: 0.1,
                layout: {
                name: 'd3-force',
                animate: true,
                fixedAfterDragging: false,
                linkId: function id(d) {
                    return d.id;
                },
                linkDistance: 200,
                manyBodyStrength: -300,
                ready: function(){},
                stop: function(){},
                tick: function(progress) {
                    //console.log('progress - ', progress);
                },
                randomize: false,
                infinite: true
                }
            });

            cy.on('click','node', (e)=>{
              //this.selectedNode2 = e.target.id();              
              var nd = vm.dataNodes.find( x => x.data.id==e.target.id());
              if( nd != null)
              {
                  console.log(nd.data.name);
                // vm.queries = nd.data.name;   
                vm.queries.push(nd.data.name);
                vm.queries = vm.queries.filter(function(value, index, arr){ 
                  return arr[index]!="全部";
                });        
              }
            });

            cy.on('click','edge', function(evt){
              var node = evt.target;
              var ed = vm.dataNodes.find( x => x.data.id==node.id());
              //console.log(ed);
              if( ed != null )
              {
                var source = vm.dataNodes.find(x=> x.data.id==ed.data.source );
                var target = vm.dataNodes.find(x=> x.data.id==ed.data.target );
                vm.queries.push( source.data.name + " " + target.data.name);
                vm.queries = vm.queries.filter(function(value, index, arr){ 
                  return arr[index]!="全部";
                }); 
              }
              
            });

            cy.on('select', 'node', (e)=>{
              this.selectedCtxMenu = e.cyTarget;
              console.log('selectedCtxMenu = ' + e.cyTarget);
            });
            


            //console.log("this.dataNodes", this.dataNodes);
            cy.add(this.dataNodes);

            console.log(cy);

            this.cyObj = cy;
        },
        async getMap() {
            let datas = [];      
            var oNode = this.derrickData.nodes;
            if(oNode!=null && oNode.length>0)
            {     
                
                for (let i = 0; i < oNode.length; i++)
                {          
                    datas.push({
                        group: 'nodes',
                        data: oNode[i]
                    })
                }
                
            };
            //console.log("datas", datas);

            
            var oEdges =this.derrickData.edges;
            if(oEdges!=null && oEdges.length>0)
            {
                for (let i = 0; i < oEdges.length; i++)
                {
                    datas.push({
                        group: 'edges',
                        data: oEdges[i]
                    })

                }
            }      

            
            this.dataNodes = datas;
        },


        async refreshCy() {
            await this.draw();
            this.cyObj.elements().remove();
            this.cyObj.add(this.dataNodes);            
            this.layoutRedraw();
        },        
        layoutRedraw() {
            var layout = this.cyObj.layout({
              name: 'd3-force',
              animate: true,
              fixedAfterDragging: false,
              linkId: function id(d) {
                  return d.id;
              },
              linkDistance: 100,
              manyBodyStrength: -300,
              randomize: false,
              infinite: true
            });
            layout.run(); 
        },

    },
    async mounted () {
        console.log("Starting...");
        await this.refreshCy();
        //await this.draw();
        console.log("End...");
    }
}

</script>



<style lang="scss" scoped>
.graphBody {
    width:100%;
    height:100vh;
    background-color: #ffffcc
}
.graphCy {
    width: 100vh;
    height: 85%;
}
</style>